import BasePlugin from '../BasePlugin'

export default class SetInvestorLinkCommand extends BasePlugin {
  async execute () {
    let investorId = {
      'investorId': this.context.getModel().id
    }
    this.context.$http.post(`${this.context.$config.api}/registryservice/plugins/execute/SetInvestorLinkCommand`,
      investorId,
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
  }
}
